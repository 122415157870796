<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <AppSubscribes />
    </template>
    <template #fallback>
      <AppLoading />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader";
// import AppSubscribes from "@/components/Users/AppSubscribes";
import AppFooter from "@/components/layouts/AppFooter";
import AppLoading from "@/components/Spinners/AppLoading.vue";
import { defineAsyncComponent, Suspense } from "vue";
const AppSubscribes = defineAsyncComponent(() =>
  import('@/components/Users/AppSubscribes')
)
export default {
  name: "Subscribes",
  components: {
    AppHeader,
    AppSubscribes,
    AppFooter,
    AppLoading,
    Suspense,
  },
};
</script>

<style>
</style>